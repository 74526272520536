import round from 'lodash/round';
import { getServiceUrl, httpPost } from 'services/api';
import { logger } from 'services/logger';
import { MatchInfo } from './sports/internal';
import { OddsStatus } from '@staycool/odds-types';
import { SerializedOdds } from '../services/odds/types';

export const getUrl = url => getServiceUrl('sports-decider', url);

export async function saveOdds(odds: Odds[]) {
    const url = getUrl('odds/manual');
    try {
        return await httpPost<SerializedOdds[]>(url, { odds });
    } catch (e) {
        if (/.*must have no more than 3 decimal places/.test(e.meta)) {
            logger.log(
                'SportsDeciderMicroservice',
                'saveOdds',
                `${odds[0].match_id}: Reducing precision to 3 decimal places`,
            );
            odds.forEach(o => {
                o.value = round(o.value, 3);
            });
            return httpPost<SerializedOdds[]>(url, { odds });
        }
    }
}

export interface Odds {
    market_id: number;
    match_id: number;
    outcome_id: number;
    status: OddsStatus;
    value: number;
}

export function postMatchInfo({ match_id, ...payload }: Partial<MatchInfo>) {
    const url = getUrl(`in-play/${match_id}/manual`);
    return httpPost<void>(url, payload);
}
