import { DatabaseFormulaSet, DatabaseMatchSettings, Serialize } from '@staycool/sports-types';
import { Category } from '@staycool/sports-types/dist/features/category/types';
import {
    GetSmartResultingResultableFactsTypeGroup,
    SmartResult,
    SmartResultingPostBody,
} from '@staycool/sports-types/smart-resulting';
import { FormInstance } from 'antd';
import { RefFeedMatchMapping } from 'microservices/reference-feed/match-mapping';
import { MatchScheduleDynamicLimitOutcome } from 'microservices/sports/dynamic-limits';
import { MatchFormulaDataWithBestLine } from 'microservices/sports/formula-data/match-formula-data';
import { ProcessedMatchMarket } from 'services/market';
import { FormulaCategoryInfo, RecalculationInputs } from 'services/odds-calculator/odds-calculator';
import { OutcomesByOutcomeId } from 'services/outcome/outcome';
import { SportName } from 'services/sport';
import { createGlobalStore, Store } from './utils';
import { MarketTypeGroup } from '@staycool/sports-types/market-type';
import { FormulaInputs } from '@staycool/sports-types/dist/features/match-formula-data/types';
import { BoFullMatchData } from '@staycool/sports-types/match';
import { RefFeedOdds } from '../services/reference-feed';
import { OutcomeRiskRow } from '@staycool/sportsbook-risk-types/dist/features/outcome-risk/types';
import { MarketResultsStatus } from '../types/resulting';

export type DynamicLimitsSettingsByMarketId = Record<
    number,
    { is_disabled: boolean; scheduleDynamicLimitOutcomes: MatchScheduleDynamicLimitOutcome[] }
>;

export enum MatchViewType {
    Cards = 'Cards',
    ManualResulting = 'ManualResulting',
}

export enum MarketInPlayStatus {
    All = 'All',
    Prematch = 'Prematch',
    Live = 'Live',
}

export interface MarketsFilter {
    group: Record<string, boolean>;
    status: Record<MarketStatus, boolean>;
    name: string;
    inPlayStatus: MarketInPlayStatus;
    viewType: MatchViewType;
    hideResulted: boolean;
    ignoreCoreWarning: boolean;
}

export enum MarketStatus {
    Disabled = 'disabled',
    Open = 'open',
    Initial = 'initial',
}

const marketsFilterStoreDefaultValue = {
    group: {} as Record<string, boolean>,
    status: {} as Record<MarketStatus, boolean>,
    name: '',
    inPlayStatus: MarketInPlayStatus.Prematch,
    viewType: MatchViewType.Cards,
    hideResulted: true,
    ignoreCoreWarning: false,
} as MarketsFilter;
export const marketsFilterStore = createGlobalStore(marketsFilterStoreDefaultValue, 'marketsFilter');

const marketByMarketIdStoreDefaultValue = {} as Record<number, ProcessedMatchMarket>;
export const marketByMarketIdStore = createGlobalStore<Record<number, ProcessedMatchMarket>>(
    marketByMarketIdStoreDefaultValue,
    'marketByMarketId',
);

export const marketTypeGroupsStore = createGlobalStore<MarketTypeGroup[]>([], 'marketTypeGroups');

export const marketIdsFromCore = createGlobalStore<number[]>([], 'marketsMadeInCore');

const displayMarketsIdsStoreDefaultValue = [] as number[];
export const displayMarketsIdsStore = createGlobalStore<number[]>(
    displayMarketsIdsStoreDefaultValue,
    'displayMarketsIds',
);

const displayResultingMarketsIdsStoreDefaultValue = [] as number[];
export const displayResultingMarketsIdsStore = createGlobalStore<number[]>(
    displayResultingMarketsIdsStoreDefaultValue,
    'displayResultingMarketsIds',
);

const formulaCategoryInfoStoreDefaultValue = {} as FormulaCategoryInfo;
export const formulaCategoryInfoStore = createGlobalStore<FormulaCategoryInfo>(
    formulaCategoryInfoStoreDefaultValue,
    'formulaCategoryInfo',
);

const dynamicLimitsSettingsByMarketIdStoreDefaultValue = {} as DynamicLimitsSettingsByMarketId;
export const dynamicLimitsSettingsByMarketIdStore = createGlobalStore(
    dynamicLimitsSettingsByMarketIdStoreDefaultValue,
    'dynamicLimitsSettingsByMarketI',
);

const outcomeRiskByOutcomeIdStoreDefaultValue = {} as Record<number, OutcomeRiskRow>;
export const outcomeRiskByOutcomeIdStore = createGlobalStore(
    outcomeRiskByOutcomeIdStoreDefaultValue,
    'outcomeRiskByOutcomeId',
);

const updatedOddsOutcomesIdsStoreDefaultValue = [] as number[];
export const updatedOddsOutcomesIdsStore = createGlobalStore(
    updatedOddsOutcomesIdsStoreDefaultValue,
    'updatedOddsOutcomesIds',
);

const initialOutcomesByOutcomeIdStoreDefaultValue = {} as OutcomesByOutcomeId;
export const initialOutcomesByOutcomeIdStore = createGlobalStore<OutcomesByOutcomeId>(
    initialOutcomesByOutcomeIdStoreDefaultValue,
    'initialOutcomesByOutcomeId',
);

const updatedLimitsOutcomesIdsStoreDefaultValue = [] as number[];
export const updatedLimitsOutcomesIdsStore = createGlobalStore(
    updatedLimitsOutcomesIdsStoreDefaultValue,
    'updatedLimitsOutcomesIds',
);

const updatedOutcomeSettingIdsStoreDefaultValue = [] as number[];
export const updatedOutcomeSettingIdsStore = createGlobalStore(
    updatedOutcomeSettingIdsStoreDefaultValue,
    'updatedOutcomeSettingIdsStore',
);

const updatedInternalCommentsIdsStoreDefaultValue = [] as number[];
export const updatedInternalCommentsIdsStore = createGlobalStore(
    updatedInternalCommentsIdsStoreDefaultValue,
    'updatedInternalCommentsIds',
);

const categoryStoreDefaultValue = {} as Category;
export const categoryStore = createGlobalStore(categoryStoreDefaultValue, 'category');

const matchStoreDefaultValue = {} as Serialize<BoFullMatchData>;
export const matchStore = createGlobalStore(matchStoreDefaultValue, 'matchStore');
export const isLoadingMatchPage = createGlobalStore(false, 'isLoadingMatchPage');
export const isBetBuilderMarketsLoadedStore = createGlobalStore(false, 'isBetBuilderMarketsLoaded');

const matchSettingsStoreDefaultValue = {} as Serialize<DatabaseMatchSettings>;
export const matchSettingsStore = createGlobalStore(matchSettingsStoreDefaultValue, 'matchSettings');

const matchInfoStoreDefaultValue = { betgeniusId: undefined, sportName: undefined } as {
    betgeniusId: undefined | number;
    coreId: undefined | number;
    sportName: undefined | SportName;
};
export const matchInfoStore = createGlobalStore<{
    betgeniusId: undefined | number;
    coreId: undefined | number;
    sportName: undefined | SportName;
}>(matchInfoStoreDefaultValue, 'matchInfo');

const formulaDataStoreDefaultValue = {} as MatchFormulaDataWithBestLine;
export const formulaDataStore = createGlobalStore(formulaDataStoreDefaultValue, 'formulaData');

const changedFormulaDataStoreDefaultValue = [] as string[];
export const changedFormulaDataStore = createGlobalStore(changedFormulaDataStoreDefaultValue, 'changedFormulaData');

const initialFormulaDataFormValuesStoreDefaultValue = {} as FormulaInputs;
export const initialFormulaDataFormValuesStore = createGlobalStore(
    initialFormulaDataFormValuesStoreDefaultValue,
    'initialFormulaDataFormValues',
);

const formulaSetsStoreDefaultValue = [] as DatabaseFormulaSet[];
export const formulaSetsStore = createGlobalStore(formulaSetsStoreDefaultValue, 'formulaSets');

const formulaDataFormStoreDefaultValue = {} as FormInstance<FormulaInputs>;
export const formulaDataFormStore = createGlobalStore(formulaDataFormStoreDefaultValue, 'formulaDataForm');

const refFeedMatchMappingStoreDefaultValue = {} as RefFeedMatchMapping;
export const refFeedMatchMappingStore = createGlobalStore(refFeedMatchMappingStoreDefaultValue, 'refFeedMatchMapping');

const isCreateNewMarketModalVisibleStoreDefaultValue = false;
export const isCreateNewMarketModalVisibleStore = createGlobalStore(
    isCreateNewMarketModalVisibleStoreDefaultValue,
    'isCreateNewMarketModalVisible',
);

const isSmartResultingModalVisibleStoreDefaultValue = false;
export const isSmartResultingModalVisibleStore = createGlobalStore(
    isSmartResultingModalVisibleStoreDefaultValue,
    'isSmartResultingModalVisible',
);

const isSmartResultingPreviewModalVisibleDefaultValue = false;
export const isSmartResultingPreviewModalVisibleStore = createGlobalStore(
    isSmartResultingPreviewModalVisibleDefaultValue,
    'isSmartResultingPreviewModalVisible',
);

const smartResultingResultsDefaultValue = [] as SmartResult[];
export const smartResultingResultsStore = createGlobalStore(smartResultingResultsDefaultValue, 'smartResultingResults');

const smartResultingScoreDefaultValue = {} as SmartResultingPostBody;
export const smartResultingScoreStore = createGlobalStore(smartResultingScoreDefaultValue, 'smartResultingScore');

const smartResultingFactTypesDefaultValue = [] as GetSmartResultingResultableFactsTypeGroup[];
export const smartResultingFactTypesStore = createGlobalStore(
    smartResultingFactTypesDefaultValue,
    'smartResultingFactTypes',
);

const isMatchEditOpenStoreDefaultValue = false;
export const isMatchEditOpenStore = createGlobalStore(isMatchEditOpenStoreDefaultValue, 'isMatchEditOpen');

const isShowingPlayersAndTeamsStoreDefaultValue = false;
export const isShowingPlayersAndTeamsStore = createGlobalStore(
    isShowingPlayersAndTeamsStoreDefaultValue,
    'isShowingPlayersAndTeams',
);

const isCreateNewLineModalVisibleStoreDefaultValue = false;
export const isCreateNewLineModalVisibleStore = createGlobalStore(
    isCreateNewLineModalVisibleStoreDefaultValue,
    'isCreateNewLineModalVisible',
);

const newLineMarketIdStoreDefaultValue = undefined as number | undefined;
export const newLineMarketIdStore = createGlobalStore(newLineMarketIdStoreDefaultValue, 'newLineMarketId');

const newLineMarketInitialValueStoreDefaultValue = undefined as number | undefined;
export const newLineMarketInitialValueStore = createGlobalStore(
    newLineMarketInitialValueStoreDefaultValue,
    ' newLineMarketInitialValue',
);

const highlightedMarketIdStoreDefaultValue = undefined as undefined | number;
export const highlightedMarketIdStore = createGlobalStore(highlightedMarketIdStoreDefaultValue, 'highlightedMarketId');

const lastHandledMarketIdStoreDefaultValue = undefined as undefined | number;
export const lastHandledMarketIdStore = createGlobalStore(lastHandledMarketIdStoreDefaultValue, 'lastHandledMarketId');

const isSavingOddsStoreDefaultValue = false;
export const isSavingOddsStore = createGlobalStore(isSavingOddsStoreDefaultValue, 'isSavingOdds');

const resultsByMarketIdStoreDefaultValue = {} as Record<number, MarketResultsStatus>;
export const resultsByMarketIdStore = createGlobalStore(resultsByMarketIdStoreDefaultValue, 'resultsByMarketId');

const isRecalculatingOddsStoreDefaultValue = false;
export const isRecalculatingOddsStore = createGlobalStore(isRecalculatingOddsStoreDefaultValue, 'isRecalculatingOdds');

const isAsbEstimatingStoreDefaultValue = false;
export const isAsbEstimatingStore = createGlobalStore(isAsbEstimatingStoreDefaultValue, 'isAsbEstimating');

const recalculationInputsStoreDefaultValue = {} as RecalculationInputs;
export const recalculationInputsStore = createGlobalStore<RecalculationInputs>(
    recalculationInputsStoreDefaultValue,
    'recalculationInputsStore',
);

const isSaveAllCardsDisabledStoreDefaultValue = false;
export const isSaveAllCardsDisabledStore = createGlobalStore(
    isSaveAllCardsDisabledStoreDefaultValue,
    'isSaveAllCardsDisabled',
);

const categoryFormulaNameByCategoryStoreDefaultValue = {};
export const categoryFormulaNameByCategoryStore = createGlobalStore<Record<number, string>>(
    categoryFormulaNameByCategoryStoreDefaultValue,
    'categoryFormulaName',
);

const refFeedOddsStoreDefaultValue = { odds: [], requestedOutcomeIds: [] };
export const refFeedOddsStore = createGlobalStore<{ odds: RefFeedOdds[]; requestedOutcomeIds: number[] }>(
    refFeedOddsStoreDefaultValue,
    'refFeedOddsStore',
);

export function cleanUpMatchPageStore() {
    const allStores: { store: Store<any>; defaultValue: any }[] = [
        { store: marketsFilterStore, defaultValue: marketsFilterStoreDefaultValue },
        { store: categoryFormulaNameByCategoryStore, defaultValue: categoryFormulaNameByCategoryStoreDefaultValue },
        { store: marketByMarketIdStore, defaultValue: marketByMarketIdStoreDefaultValue },
        { store: displayMarketsIdsStore, defaultValue: displayMarketsIdsStoreDefaultValue },
        { store: displayResultingMarketsIdsStore, defaultValue: displayResultingMarketsIdsStoreDefaultValue },
        { store: formulaCategoryInfoStore, defaultValue: formulaCategoryInfoStoreDefaultValue },
        { store: dynamicLimitsSettingsByMarketIdStore, defaultValue: dynamicLimitsSettingsByMarketIdStoreDefaultValue },
        { store: outcomeRiskByOutcomeIdStore, defaultValue: outcomeRiskByOutcomeIdStoreDefaultValue },
        { store: updatedOddsOutcomesIdsStore, defaultValue: updatedOddsOutcomesIdsStoreDefaultValue },
        { store: initialOutcomesByOutcomeIdStore, defaultValue: initialOutcomesByOutcomeIdStoreDefaultValue },
        { store: updatedLimitsOutcomesIdsStore, defaultValue: updatedLimitsOutcomesIdsStoreDefaultValue },
        { store: updatedOutcomeSettingIdsStore, defaultValue: updatedOutcomeSettingIdsStoreDefaultValue },
        { store: categoryStore, defaultValue: categoryStoreDefaultValue },
        { store: matchStore, defaultValue: matchStoreDefaultValue },
        { store: matchSettingsStore, defaultValue: matchSettingsStoreDefaultValue },
        { store: matchInfoStore, defaultValue: matchInfoStoreDefaultValue },
        { store: formulaDataStore, defaultValue: formulaDataStoreDefaultValue },
        { store: changedFormulaDataStore, defaultValue: changedFormulaDataStoreDefaultValue },
        { store: initialFormulaDataFormValuesStore, defaultValue: initialFormulaDataFormValuesStoreDefaultValue },
        { store: formulaDataFormStore, defaultValue: formulaDataFormStoreDefaultValue },
        { store: refFeedMatchMappingStore, defaultValue: refFeedMatchMappingStoreDefaultValue },
        {
            store: isCreateNewMarketModalVisibleStore,
            defaultValue: isCreateNewMarketModalVisibleStoreDefaultValue,
        },
        {
            store: isSmartResultingModalVisibleStore,
            defaultValue: isSmartResultingModalVisibleStoreDefaultValue,
        },
        {
            store: isSmartResultingPreviewModalVisibleStore,
            defaultValue: isSmartResultingPreviewModalVisibleDefaultValue,
        },
        { store: smartResultingResultsStore, defaultValue: smartResultingResultsDefaultValue },
        { store: smartResultingScoreStore, defaultValue: smartResultingScoreDefaultValue },
        { store: smartResultingFactTypesStore, defaultValue: smartResultingFactTypesDefaultValue },
        { store: isMatchEditOpenStore, defaultValue: isMatchEditOpenStoreDefaultValue },
        { store: isShowingPlayersAndTeamsStore, defaultValue: isShowingPlayersAndTeamsStoreDefaultValue },
        { store: isCreateNewLineModalVisibleStore, defaultValue: isCreateNewLineModalVisibleStoreDefaultValue },
        { store: newLineMarketIdStore, defaultValue: newLineMarketIdStoreDefaultValue },
        { store: newLineMarketInitialValueStore, defaultValue: newLineMarketInitialValueStoreDefaultValue },
        { store: highlightedMarketIdStore, defaultValue: highlightedMarketIdStoreDefaultValue },
        { store: isSavingOddsStore, defaultValue: isSavingOddsStoreDefaultValue },
        { store: resultsByMarketIdStore, defaultValue: resultsByMarketIdStoreDefaultValue },
        {
            store: refFeedOddsStore,
            defaultValue: refFeedOddsStoreDefaultValue,
        },
    ];

    allStores.forEach(({ store, defaultValue }) => store.set(defaultValue));
}
