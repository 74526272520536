import { getStoreValue, store } from 'stores/store';
import { isFeatureAvailable } from 'services/features';
import dayjs, { ConfigType, ManipulateType } from 'services/dayjs';

export const EUROPEAN_DATE_FORMAT = {
    daysForUpdate: 'YYYY-MM-DD',
    minutesForUpdate: 'YYYY-MM-DDTHH:mm',
    secondsForUpdate: 'YYYY-MM-DDTHH:mm:ss',
    millisecondsForUpdate: 'YYYY-MM-DDTHH:mm:ss.SSS',
    millisecondsForUpdateZulu: 'YYYY-MM-DDTHH:mm:ss.SSS[Z]',
    millisecondsISO: 'YYYY-MM-DD HH:mm:ss.SSS',
    days: 'DD.MM.YYYY',
    minutes: 'DD.MM.YYYY HH:mm',
    seconds: 'DD.MM.YYYY HH:mm:ss',
    milliseconds: 'DD.MM.YYYY HH:mm:ss.SSS',
    human: 'MMM DD, YYYY',
    humanMinutes: 'MMM DD, YYYY HH:mm',
    nameOfTheWeekDay: 'dddd',
    dayMonth: 'DD.MM',
    hoursMinutesSeconds: 'HH:mm:ss',
};

export const USA_DATE_FORMAT = {
    days: 'MM-DD-YYYY',
    minutes: 'MM-DD-YYYY hh:mm A',
    seconds: 'MM-DD-YYYY hh:mm:ss A',
    milliseconds: 'MM-DD-YYYY hh:mm:ss.SSS A',
    humanMinutes: 'MMM DD, YYYY hh:mm A',
    dayMonth: 'MM-DD',
    hoursMinutesSeconds: 'hh:mm:ss A',
};

export const dateFormat = (
    date: ConfigType,
    format: keyof typeof EUROPEAN_DATE_FORMAT = 'minutes',
    timeZone?: string,
    keepLocalTime = true,
) => {
    if (!date) {
        return '';
    }

    const decidedFormat = getDateFormat(format);
    const momentDate = timeZone ? dayjs(date).tz(timeZone, keepLocalTime) : dayjs(date);

    return dayjs(momentDate).format(decidedFormat);
};

export function getDateFormat(format: keyof typeof EUROPEAN_DATE_FORMAT = 'minutes') {
    const defaultFormat = EUROPEAN_DATE_FORMAT[format];
    const usaFormat = USA_DATE_FORMAT[format];
    const isAmericanOddsFormat = getStoreValue(store.sportsbook.isOddsFormatAmerican);

    return (isAmericanOddsFormat && isFeatureAvailable('usaDateFormat') ? usaFormat : defaultFormat) || defaultFormat;
}

export function formatDuration(startDate: string | Date, endDate: string | Date) {
    const duration = dayjs.duration(dayjs(endDate).diff(dayjs(startDate)));
    const [months, days, hours, minutes] = ['months', 'days', 'hours', 'minutes'].map(method => duration[method]());
    if (months) {
        return `${months} ${days}d`;
    }
    if (days) {
        return `${days}d ${hours}h`;
    }
    if (hours) {
        return `${hours}h ${minutes}m`;
    }
    return `${minutes}m`;
}

export function getDifferenceInMilliseconds(startDate: string | Date, endDate: string | Date) {
    const duration = dayjs.duration(dayjs(endDate).diff(dayjs(startDate)));
    return duration.asMilliseconds();
}

export function isDateLaterThanPeriodAgo(date: Date | undefined, unitOfTime: ManipulateType, period: number) {
    return !!date && dayjs().diff(date, unitOfTime) < period;
}

export function getAgeFromDateOfBirth(date: ConfigType) {
    return dayjs().diff(dateFormat(date, 'daysForUpdate'), 'year');
}
