import { BoMatch } from '@staycool/sports-types/dist/features/match/types';
import pick from 'lodash/pick';
import { DatabaseMatch, MatchStatus } from 'types/match';
import { Serialize, UserSettingsLayout } from '@staycool/sports-types';
import { updateMatch } from 'microservices/sports/match';
import { getCoreMatchDetails, toggleMatchUpdatesFromCoreFeed } from '../microservices/client-receiver';
import { getRoute } from './router';
import { ROUTES } from 'routes';
import { getStoreValue } from '../stores/utils';
import { store } from '../stores/store';
import { isFeatureAvailable } from 'services/features';
import { openNewTab } from './util';

export const STORAGE_KEY_FAVORITE_MARKETS = 'favouriteMarketsByMatchId';
export const STORAGE_KEY_MARKET_COUNT_PER_ROW = 'marketCardCountPerRow';
export const STORAGE_KEY_OUTCOME_DISPLAY_SELECTION = 'outcomeDisplaySelection';

export const FAVORITE_MARKET_TYPE_GROUP_NAME = 'Favorites';

export const UNIQUE_VIOLATION_ERROR_CODE = 3901;
export const IS_COMPACT_MARKET_CARDS_KEY = 'isCompactMarketCards';
export const MARKET_CARD_ELEMENT_SIZE_KEY = 'marketCardElementSize';
export const clientsWithOwnCore = ['wynnbet'];
export const staticCorePaths = {
    dev: 'http://bo.coredev.com',
    stage: 'https://core.core.gansportsbookstage.com',
    qa: 'https://core.core.gansportsbookqa.com',
    prod: 'https://core.gansportsbook.com',
    'wynnbet-stage': 'https://bo.wynnbet-core.gansportsbookstage.com/',
    'wynnbet-qa': 'https://bo.wynnbet-core.gansportsbookqa.com/',
    'wynnbet-prod': 'https://bo.wynnbet-core.gansportsbook.com/',
};

export const matchStatuses: MatchStatus[] = [
    'INITIAL',
    'OPEN',
    'LIVE',
    'OFFTHEBOARD',
    'DISABLED',
    'FINISHED',
    'CLOSED',
    'CANCELLED',
    'LOCKED',
];

const nextMatchStatus: Record<Extract<MatchStatus, 'INITIAL' | 'DISABLED' | 'OPEN' | 'OFFTHEBOARD'>, MatchStatus> = {
    INITIAL: 'DISABLED',
    DISABLED: 'OPEN',
    OPEN: 'OFFTHEBOARD',
    OFFTHEBOARD: 'DISABLED',
};

export function getNextMatchStatus(status: MatchStatus, isStateActive: boolean): MatchStatus | undefined {
    let newStatus: MatchStatus = isStateActive ? 'OPEN' : 'OFFTHEBOARD';
    if (!['LIVE', 'CLOSED'].includes(status.toUpperCase())) {
        newStatus = nextMatchStatus[status];
    }
    return newStatus;
}

export function getMatchFieldsForForm(match: Serialize<DatabaseMatch | BoMatch>) {
    const keysOfType = <T extends keyof BoMatch>(arr: T[]): T[] => arr;
    const fieldsToPick = keysOfType([
        'name',
        'match_start',
        'betting_start',
        'betting_end',
        'core_feed_enabled',
        'expected_result_time',
        'status',
        'expected_goals',
        'external_comment',
        'excluded_countries',
        'betbuilder_excluded_countries',
        'cash_out_disabled',
        'high_risk',
        'inplay',
        'livebet_our',
        'bg_enabled',
        'score_home',
        'score_away',
        'featured_countries',
        'home_team_obj',
        'away_team_obj',
        'neutral_field',
    ]);
    type ArrayOf<T> = T extends (infer U)[] ? U : never;
    return pick(match, fieldsToPick) as Pick<Serialize<BoMatch>, ArrayOf<typeof fieldsToPick>>;
}

export function formatMatchName(
    layout: UserSettingsLayout,
    matchName: string,
    awayTeamName?: string,
    homeTeamName?: string,
    homeTeamRotationNumber?: number,
    awayTeamRotationNumber?: number,
) {
    const hasRotationNumber = !!homeTeamRotationNumber && !!awayTeamRotationNumber;

    if (layout === 'AMERICAN') {
        return hasRotationNumber
            ? `[${awayTeamRotationNumber}] ${awayTeamName} @ [${homeTeamRotationNumber}] ${homeTeamName}`
            : `${awayTeamName} @ ${homeTeamName}`;
    }

    return matchName;
}

export const isMatchDisabled = market =>
    !!market && (market.match_status === 'OFFTHEBOARD' || market.match_status === 'DISABLED');

export async function openInitialMatch(match: Serialize<BoMatch>) {
    const openStatus: MatchStatus = 'OPEN';
    if (!match || match.status !== 'INITIAL') {
        return;
    }
    return await updateMatch(match.id, { status: openStatus });
}

export async function switchMatchUpdatesFromCoreFeed(match: Serialize<BoMatch>, formValueCoreFeedEnabled) {
    if (!isFeatureAvailable('betgenius') && match?.core_feed_enabled !== formValueCoreFeedEnabled) {
        return await toggleMatchUpdatesFromCoreFeed(match?.id, formValueCoreFeedEnabled);
    }
}

export async function openMatchInCore(categoryId: number, matchId: number, marketId?: number) {
    const url = await getMatchUrlInCore(categoryId, matchId, marketId);
    openNewTab(url);
}

export async function getMatchUrlInCore(categoryId: number, matchId: number, marketId?: number) {
    const mappingDetails = await getCoreMatchDetails({ matchId, categoryId, marketId });
    if (!mappingDetails) {
        return '';
    }
    const { core_match_id, core_category_id, core_market_id } = mappingDetails;

    const { ENVIRONMENT, CLIENT_NAME } = getStoreValue(store.environment);
    const coreInstance = clientsWithOwnCore.includes(CLIENT_NAME) ? `${CLIENT_NAME}-${ENVIRONMENT}` : ENVIRONMENT;
    let newTabUrl = `${staticCorePaths[coreInstance]}${getRoute(
        ROUTES.sportsbook.categories,
    )}/${core_category_id}/matches/${core_match_id}`;

    if (core_market_id) {
        newTabUrl += `?market=${core_market_id}`;
    }

    return newTabUrl;
}
